import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

// Custom components
import App from 'App';
import { store, persistor } from 'Common/Store';
import { initializeIcons, ThemeProvider } from '@fluentui/react';

initializeIcons();

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <App />
        </Router>
      </PersistGate>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);
