import React from 'react';
import { Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import useMount from 'Common/Hooks/useMount';

import 'Common/Styles/css/reset.css';
import 'Common/Styles/css/font-face.css';
import 'Common/Styles/css/helpers.css';
import 'Common/Styles/css/ui.css';
import 'Common/Styles/css/admin.css';

import { mainConfig } from 'Configs/Routes/mainConfig';
import SubRouting from 'Common/Routes/SubRouting';

export const history = createBrowserHistory();

const App = () => {
  useMount(() => {
    if (process.env.NODE_ENV === 'production') {
      Object.keys(console).forEach(method => {
        if (method !== 'error') {
          console[method] = function noop() {};
        }
      });
    }
  });

  return (
    <Switch>
      {mainConfig.map((route, i) => (
        <SubRouting key={i} {...route} />
      ))}
    </Switch>
  );
};

export default App;
